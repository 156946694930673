.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3; // 1 for card, 2 for overlay
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.fields-container > *:not(:last-child) {
  margin-top: 20px;
  width: 100%;
}

.grey-bar {
  background: #3c3c3c;
  height: 20px;
}

.modal-wrapper {
  margin-top: 5%;
  margin-left: 25%;
  background-color: #fff;
  width: 55%;
  height: 90%;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
  padding: 30px 40px;
}

.modal-buttons {
  width: 100%;
}

.modal-close-container {
  justify-content: right;
  float: right;
}

.modal-close {
  font-size: 14px;
  background: #fff;
  border: none;
  font-weight: bolder;
}

.modal-prev {
  width: 90px;
  height: 36px;
  background: #f2f2f2;
  border: 1px solid #a9a9a9;

  .arrow-up-icon-enabled {
    transform: rotate(180deg);
  }
}

.modal-next {
  width: 90px;
  height: 36px;
  background: #f2f2f2;
  border: 1px solid #a9a9a9;

  .arrow-down-icon-disabled {
    transform: rotate(180deg);
  }
}

.disabled-button-border {
  border: 1px solid #d1d1d1;
}

.modal-buttons > button {
  margin: 0px 7px;
}

.modal-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.modal-title,
.modal-title > * {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  letter-spacing: -0.035em;
  align-self: last baseline;
  flex-basis: 67%;
}

.modal-desc,
.modal-desc > * {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  justify-content: right;
  text-align: right;
  align-self: last baseline;
  flex-basis: 33%;
}

.modal-img-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.modal-img {
  width: 100%;
  padding-bottom: 40%;
  position: relative;
  align-self: center;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.modal-img > img {
  object-fit: cover;
  // height: auto;
  // max-height: 18.667vw;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  position: absolute;
}

.modal-img-bottom {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: #000000;
  gap: 60px;
  align-content: center;
  justify-content: space-between;
}

.modal-madeby {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 10px;
}

.modal-madeby-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #747474;
  white-space: nowrap;
  overflow: hidden;
}

.modal-madeby-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
}

.modal-hospital {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 10px;
}

.modal-hospital-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #747474;
  white-space: nowrap;
  overflow: hidden;
}

.modal-hospital-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
}

.category-download-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-container {
  justify-content: right;
  float: right;
}

.problem-container,
.solution-container,
.source-container,
.notes-container,
.disclaimer-container {
  display: block;
  margin-top: 30px;
}

.problem-title,
.solution-title,
.source-title,
.notes-title,
.disclaimer-title {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 171%;

  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-feature-settings: "cv05" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on,
    "cv09" on, "cv10" on;

  color: #000000;

  margin-bottom: 8px;
}

.problem-desc,
.solution-desc,
.source-desc,
.solution-desc > p,
.notes-desc,
.disclaimer-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  word-wrap: break-word;

  letter-spacing: -0.006em;
  font-feature-settings: "tnum" on, "lnum" on, "cv05" on, "cv01" on, "cv02" on,
    "cv04" on, "cv03" on, "cv09" on, "cv10" on;

  color: #000000;
}

.modal-download {
  width: 152px;
  height: 36px;
  background-color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;

  @media (max-width: 1366px) {
    font-size: 12px;
  }

  line-height: 20px;
  border: 1px solid #0f796f;

  .button-holder {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .icon {
      flex-basis: 5%;

      .download-icon {
        margin: 11px;
      }
    }

    .text {
      flex-basis: 95%;
      padding: 6px 0px;
      color: #2c2e34;

      @media (max-width: 1366px) {
        padding: 7px 0px;
      }
    }
  }
}
