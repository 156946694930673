$padding: 20px;

.alert {
  width: calc(100% - 2 * $padding);
  height: 50px; /* Change this to the height of your Alert component */
  background-color: black;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $padding;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.alert * {
  font-weight: 600;
}

.info-icon {
  padding: 12.75px 11px 11px 0px;
}

.alert a {
  color: white;
  font-size: 14px;
}

.align-left {
  width: 100%;
  text-align: left;
  justify-content: center;
  line-height: 20px;
  font-weight: 600;
  font-family: 'Inter';
  font-style: normal;
}

.alert p {
  margin: 0;
}

.alert img {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
