.cardCategory {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    flex-wrap: wrap;

    &--searchBar {
        overflow-y: hidden;
        overflow-x: scroll;
        margin-bottom: 0px;
        flex-wrap: nowrap;
    }

    .category {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        gap: 4px;
        border-radius: 50px;
        flex: none;
        order: 0;
        margin: 4px 8px 0px 0px;
        display: inline-block;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
            margin: 0;
            white-space: nowrap;
        }
    }
}

#categoryColor {
    display: inline-block;
    margin: 5px;
    height: fit-content;
}
