@font-face {
    font-family: "Inter";
    src: local("Inter"), url(../fonts/Inter-Regular.ttf) format("truetype");
}

.cardContainer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Inter';

    .column-0 {
        display: flex;
        flex-direction: column;
    }

    .column-1 {
        display: flex;
        flex-direction: column;
    }

    .column-2 {
        display: flex;
        flex-direction: column;
    }
}
