@import "./styles/dashboard.scss";
@import "./styles/modal.scss";
@import "./styles/card.scss";
@import "./styles/cardCategory.scss";
@import "./styles/cardContainer.scss";
@import "./styles/search.scss";
@import "./styles/alert.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Space+Mono&display=swap");

$sidebar-width: 25vw;

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter-Regular.ttf) format("truetype");
}

.hide {
  visibility: hidden;
}

.viewport {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.just-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.side-bar {
  background-color: #272727;
  position: relative;
  height: 100%;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-basis: 10%;
  }
}

.side-bar > .header > .title {
  color: white;
  font-size: 30px;
  margin-top: 72px;
  margin-left: 32px;
}

.side-bar > .header > .desc {
  color: white;
  font-size: 14px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 20px;
}

.multi-select {
  border: 2px solid #000000;
  z-index: 1;
}

.multi-select-container {
  margin-left: 30px;
  margin-right: 15px;
  z-index: 1;
}

.multi-select-container > .multi-select-title {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-feature-settings: "cv05" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on,
    "cv09" on, "cv10" on;

  color: white;

  margin-bottom: 16px;
  margin-top: 40px;
}

.layout-1 {
  display: flex;
  height: calc(100% - 50px);
  width: 100%;
  margin: 0;
  padding: 0;

  .body {
    height: 100%;
    width: 100vw - $sidebar-width;
    background-color: #f7f3ef;
    overflow: scroll;
    font-family: Inter;
  }
}

.layout-2 {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  .body {
    height: 100%;
    width: 100vw - $sidebar-width;
    background-color: #f7f3ef;
    overflow: scroll;
    font-family: "Inter";
  }
}

.footer-title {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-feature-settings: "cv05" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on,
    "cv09" on, "cv10" on;

  color: #ffffff;
  margin-left: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.footer-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  word-wrap: break-word;
  font-feature-settings: "tnum" on, "lnum" on, "cv05" on, "cv01" on, "cv02" on,
    "cv04" on, "cv03" on, "cv09" on, "cv10" on;

  color: #ffffff;
  margin-left: 32px;
  margin-right: 32px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: fit-content;
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  height: fit-content;
  width: 100%;
}

.content {
  position: absolute;
  top: 250px;
  bottom: 130px;
  overflow: auto;
  width: 100%;
}
