.options {
  font-family: "Inter";
  font-style: normal;

  span {
    padding: 5px;
    color: #000000;
  }
}

::-webkit-scrollbar {
  opacity: 5%;
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  opacity: 5%;
}

::-webkit-scrollbar-track {
  opacity: 5%;
}