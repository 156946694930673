.dashboard {
    flex-direction: row;
    display: flex;
    justify-content: center;
    font-family: "Inter";
}

.dashboard-heading-1 {
    font-size: 34px;
    font-family: "Inter";
    color: #C2BAB1;
    text-align: right;
    margin-top: 72px;
    // width: 52%;
    width: 831px;
    font-style: normal;
    font-weight: 500;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: -0.05em;
    float: right;
}

.dashboard-heading-2 {
    font-size: 34px;
    font-family: "Inter";
    color: #C2BAB1;
    text-align: left;
    margin-top: 72px;
    float: left;
    letter-spacing: -0.05em;
}

.Wrapper-1 {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    margin-bottom: 40px;
    top:0; right:0;
}

.Wrapper-2 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    margin-bottom: 40px;
    top:0; left:0;
}

span {
    display: inline;
    color: #EB3333;
}

hr {
    border: none;
    border-top: 1px dotted white;
    color: white;
    background-color: white;
    opacity: 20%;
    height: 1px;
    width: 100%;
    margin-top: 25px;
}

.count {
    margin-left: 5px;
    margin-right: 5px;
}
