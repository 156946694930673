.card-wrapper {
    position: relative;

    .overlay-layer {
        position: absolute;
        width: 251px;
        height: 100%;
        background: rgba(235, 51, 51, 0.1);
        z-index: 2;
        opacity: 0;
        margin: 0px 12px;
        transition: opacity 0.25s ease-out;
        // pointer-events: none;
    }
    
    .overlay-layer:active {
        opacity: 1;
    }
    
    .overlay-layer:hover {
        opacity: 1;
    }
}

.card {
    background-color: #F7F3EF;
    border: 1.11px solid black;
    margin: 12px;

    display: flex;
    flex-direction: column;

    width: 251px;

    z-index: 1;
    position: relative;

    img {
        height: 150px;
        object-fit: cover;
        border-bottom: 1px black solid;
    }

    .details {
        padding: 28px 16px 0px 16px;
        font-family: 'Inter';
        font-style: normal;

        h2 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 350;
            font-size: 22px;
            line-height: 27px;
            leading-trim: both;
            text-edge: cap;
            color: #000000;
            margin: 0px 0px 16px 0px;
        }

        h3 {
            font-weight: 600;
            font-size: 10px;
            line-height: 24px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: rgba(62, 62, 62, 0.8);
            margin: 0px;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin: 0px;
            margin-bottom: 16px;
        }

        .madeBy{
            margin-bottom: 16px;

            display: flex;
            flex-direction: row;
            overflow: hidden;

                .info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 4px 8px;
                    margin-right: 5px;
                    gap: 4px;
                    margin-top: 4px;
                    background: #D9D9D9;
                    display: inline-block;

                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #000000;
                    margin: 0;
                    white-space: nowrap;
                }
            }
        }
    }
}
